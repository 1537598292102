<template>
  <div
    class="ActivityTransitionView"
    @click="gotoNext()">
    <!-- <div
      class="stepTitle"
    >
      <template v-if="step.number">
        <span class="stepLabel">{{ $tc('dashboard.general.step') }} </span> <span class="stepNumber">{{ step.number }}:</span>&nbsp;<span v-html="step.title" />
      </template>
      <span
        v-else
        class="stepLabel"
        v-html="step.title"
      />
    </div> -->
    <div class="label">
      {{ $t(`activities.${activityName}`) }}
    </div>

    <div
      class="ActivityIcon"
      :style="{ backgroundColor: iconBackground }">
      <svgicon
        :icon="iconMap[activityName]"
        :color="iconColor" />
    </div>
  </div>
</template>

<translations>
  activities:
    read: 'Read'
    read_no: 'Les'

    # memorize: 'Overview'
    # memorize_no: 'Oversikt'
    # quiz: 'Quiz Overview'
    # quiz_no: 'Quiz oversikt'
    # memorize2: 'Details'
    # memorize2_no: 'Detaljer'
    # quiz2: 'Quiz Details'
    # quiz2_no: 'Quiz detaljer'

    memorize: 'Memo'
    quiz: 'Quiz'
    memorize2: 'Memo'
    quiz2: 'Quiz'
</translations>

<script>
import Color from 'color';

export default {
    props: {
        stepId: {
            type: String
        },
        activityName: {
            type: String,
            required: true
        },
        delay: {
            type: Number,
            default: 0
        }
    },
    inject: ['theme'],
    computed: {
        step() {
            return this.$store.state.moduleCourse.steps[this.stepId];
        },
        iconColor() {
            // return Color(this.theme.color).mix(Color('black'), 0.8).hex()
            return Color(this.theme.color).mix(Color('black'), 0.5).hex();
        },
        iconBackground() {
            return Color(this.theme.color).mix(Color('white'), 0.8).desaturate(0.5).hex();
        }
    },
    created() {
        this.iconMap = {
            read: 'activities/read',
            memorize: 'activities/memo-overview',
            quiz: 'activities/quiz',
            memorize2: 'activities/memo-details',
            quiz2: 'activities/quiz'
        };
    },
    mounted() {
        this.timeoutId = setTimeout(() => {
            this.gotoNext();
        }, 1000 + this.delay);
    },
    beforeDestroy() {
        clearTimeout(this.timeoutId);
    },
    methods: {
        gotoNext() {
            clearTimeout(this.timeoutId);
            if (this._navDone) {
                return;
            }
            this._navDone = true;
            this.$router.replace({
                name: 'Course-ActivityRedirect',
                params: this.$route.params
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.ActivityTransitionView {
    padding: 2em;
    color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.stepTitle {
    color: rgba(white, 0.7);
    margin-bottom: 0.3em;
}
.label {
    font-weight: 600;
    font-size: 300%;
    color: rgba(white, 0.9);
}

.ActivityIcon {
    $size: 12em;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: rgba(white, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 3em;

    .svg-icon {
        $size: 30%;
        width: $size;
        height: $size;
        fill: rgba(white, 0.7);
    }
}
</style>
